export default {
  siteTitle: 'Get bonus',
  blogerName: 'GEO SLOTS',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Geoslots1'
    },
    {
      name: 'telegram',
      url: 'https://t.me/GEOSLT'
    },
    {
      name: 'whatsapp',
      url: 'https://chat.whatsapp.com/Iot8fjhqYLwDnQWEXSuLNA'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c1574b34d',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c59c0d973',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/cc0bfeec4',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c0a90bb5a',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/cfb4da3bd',
      gameTitle: 'Starda Queen Million (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c265d5b4c',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/ce88d7ef2',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c56620ae3',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/ccbc493b4',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c2b46af28',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>RDS50</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'RDS50',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
